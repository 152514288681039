import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <footer className="container-fluid text-center">
      <div className="row">
        <div className="col-lg-7 offset-lg-1 col-xl-6 offset-xl-2 align-self-center text-lg-left">
          <span className="mr-lg-3">
            &copy; {new Date().getFullYear()} Meddo. All rights reserved.{" "}
          </span>
          <span>
            <Link to={"/privacy"}>Privacy Policy</Link>
            {" • "}
            <Link to={"/terms"}>Terms &amp; Conditions</Link>
            {" • "}
            <a href="mailto: hello@meddo.io">Contact&nbsp;Us</a>
          </span>
        </div>
        <div className="col-lg-3 text-lg-right">
          <a
            href="https://www.linkedin.com/company/meddo-software/"
            target="_blank"
            rel="noreferrer"
            aria-hidden="true"
            tabIndex="-1"
          >
            <svg
              className="mt-n1"
              width="26"
              height="26"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.354828 16.4097C0.354828 7.57311 7.50383 0.409668 16.3226 0.409668C25.1413 0.409668 32.2903 7.57311 32.2903 16.4097C32.2903 25.2462 25.1413 32.4097 16.3226 32.4097C7.50383 32.4097 0.354828 25.2462 0.354828 16.4097Z"
                fill="#A7E8EA"
              />
              <path
                d="M11.8774 10.2915C11.8539 9.22327 11.0916 8.40967 9.85368 8.40967C8.61576 8.40967 7.80646 9.22327 7.80646 10.2915C7.80646 11.3375 8.59184 12.1746 9.8067 12.1746H9.82983C11.0916 12.1746 11.8774 11.3375 11.8774 10.2915Z"
                fill="#1B2F64"
              />
              <path
                d="M11.6391 13.6616H8.02044V24.5561H11.6391V13.6616Z"
                fill="#1B2F64"
              />
              <path
                d="M20.5229 13.4058C22.9041 13.4058 24.6893 14.9632 24.6893 18.3095L24.6891 24.5561H21.0706V18.7275C21.0706 17.2635 20.547 16.2645 19.237 16.2645C18.2373 16.2645 17.6419 16.938 17.3803 17.5885C17.2846 17.8216 17.2612 18.1464 17.2612 18.472V24.5563H13.6421C13.6421 24.5563 13.6898 14.6842 13.6421 13.6619H17.2612V15.205C17.7414 14.4632 18.6016 13.4058 20.5229 13.4058Z"
                fill="#1B2F64"
              />
            </svg>
          </a>{" "}
          <a
            href="https://www.linkedin.com/company/meddo-software/"
            target="_blank"
            rel="noreferrer"
          >
            Follow us on LinkedIn!
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
