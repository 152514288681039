import React, { useEffect, useState, useRef } from "react";
import "./Resources.scss";
import { Link, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Pagination from "@mui/material/Pagination";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SearchIcon from "@mui/icons-material/Search";
import { MailingListForm } from "./MailingListForm";
import PrismicHelper from "../../helpers/prismicHelper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const PageLength = 4;

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 992,
    },
  },
});

export const Resources = () => {
  const [featured, setFeatured] = useState(null);
  const [showFeatured, setShowFeatured] = useState(true);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [filters, setFilters] = useState({});
  const [numPages, setNumPages] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [resourceTags, setResourceTags] = useState([]);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line
  let [_, setSearchParams] = useSearchParams({});

  const headingRef = useRef(null);

  const onPageChange = function (event, value) {
    setCurPage(value);
  };

  const onSearchChange = function (event) {
    const updatedFilters = { ...filters, text: event.target.value };

    let featuredIsDisplayed = !hasFilters(updatedFilters);
    setShowFeatured(featuredIsDisplayed);

    let newFilteredResources = applyFilters(resources, updatedFilters);
    setFilteredResources(newFilteredResources);

    let newNumPages = Math.max(
      Math.floor(newFilteredResources.length / PageLength),
      1
    );

    setFilters((currFilters) => ({ ...currFilters, text: event.target.value }));
    setNumPages(newNumPages);
    setCurPage(1);
  };

  const onTypeFilter = function (event) {
    handleFilterChange(event, "types");
  };

  const onTagFilter = function (event) {
    handleFilterChange(event, "tags");
  };

  const handleFilterChange = function (event, key) {
    let newFilters = updateFilters(
      filters,
      key,
      event.target.name,
      event.target.checked
    );
    setFilters(newFilters);
    setShowFeatured(!hasFilters(newFilters));

    let newFilteredResources = applyFilters(resources, filters);
    setFilteredResources(newFilteredResources);

    let newNumPages = Math.max(
      Math.floor(newFilteredResources.length / PageLength),
      1
    );
    setNumPages(newNumPages);
    setCurPage(1);

    setSearchParams({});
  };

  const updateFilters = function (filtersObj, key, value, isChecked) {
    if (!filtersObj[key]) {
      filtersObj[key] = [];
    }

    filtersObj[key] = filtersObj[key].filter((t) => t !== value);

    if (isChecked) {
      filtersObj[key].push(value);
    }

    return filtersObj;
  };

  const hasFilters = function (filtersObj) {
    return filtersObj && Object.values(filtersObj).some((f) => f.length > 0);
  };

  const applyFilters = function (resourceList, filtersObj) {
    let searchText = filtersObj.text || "";
    let validTypes = filtersObj.types || [];
    let validTags = filtersObj.tags || [];

    let newFilteredResources = resourceList.filter((r) => {
      let isValidText =
        searchText.length === 0 ||
        r.data.post_title[0].text
          .toLowerCase()
          .includes(searchText.toLowerCase());
      let isValidType = validTypes.length === 0 || validTypes.includes(r.type);
      let isValidTags =
        validTags.length === 0 || validTags.some((tag) => r.tags.includes(tag));
      return isValidText && isValidType && isValidTags;
    });

    return newFilteredResources;
  };

  const uniq = function (array) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  };

  useEffect(() => {
    const fetchData = async () => {
      let newResources = await PrismicHelper.getResources();
      setResources(newResources);

      let params = new URLSearchParams(window.location.search);
      let queryTags = params.get("tags") ? params.get("tags").split(",") : [];
      let newFilters = {};
      queryTags.forEach((t) => {
        newFilters = updateFilters(newFilters, "tags", t, true);
      });
      setFilters(newFilters);

      let newFilteredResources = applyFilters(newResources, newFilters);
      setFilteredResources(newFilteredResources);
      setShowFeatured(!hasFilters(newFilters));

      let newNumPages = Math.max(
        Math.floor(newFilteredResources.length / PageLength),
        1
      );
      setNumPages(newNumPages);
      setCurPage(1);

      let featuredResource = await PrismicHelper.getFeaturedResource();
      let featuredUid = featuredResource.data.featured_post.uid;
      let newFeaturedResource =
        newResources.find((r) => r.uid === featuredUid) || {};

      setFeatured(newFeaturedResource);

      let newResourceTypes = uniq(newResources.map((r) => r.type))
        .sort()
        .filter((t) => t);
      setResourceTypes(newResourceTypes);

      let newResourceTags = uniq(newResources.map((r) => r.tags).flat())
        .sort()
        .filter((t) => t);
      setResourceTags(newResourceTags);

      setLoading(false);
    };

    fetchData();

    return () => {
      setLoading(true);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header
        headingRef={headingRef}
        title="Resources: Meddo - Strategy Management Software"
      />
      <div className="resources">
        <div className="resources__container--outer">
          <div className="resources__container--inner">
            <div className="resources__container--hero">
              <h1
                className="resources__text--heading focusable-heading"
                ref={headingRef}
                tabIndex={-1}
              >
                Resources
              </h1>
              <p className="resources__text--description">
                Get the latest in business strategy management, industry trends,
                and product updates with Meddo’s blogs, videos, case studies,
                and more.
              </p>
            </div>
          </div>
          <Container className="content">
            {loading ? (
              <div style={{ width: "50px", margin: "0 auto" }}>
                <CircularProgress size={40} color="primary" />
              </div>
            ) : (
              <Grid container spacing={2.5}>
                <Grid item xs={12} sm={9} className="last-mobile">
                  <Grid container spacing={2.5}>
                    {featured && showFeatured && (
                      <Grid item xs={12}>
                        <Box className="featured">
                          <Grid container>
                            <Grid item xs={12} sm={5} className="featured-text">
                              <Typography variant="h5">FEATURED</Typography>
                              <Typography variant="h4">
                                {featured.data.post_title[0].text}
                              </Typography>
                              <div className="teaser-text">
                                {featured.data.body_content[0].text.substring(
                                  0,
                                  featured.data.body_content[0].text.indexOf(
                                    "."
                                  ) + 1
                                )}
                              </div>
                              <div>
                                <Link
                                  to={`/resources/${featured.type}/${featured.uid}`}
                                >
                                  Read the {featured.type.replace(/_/g, " ")}{" "}
                                  &#8594;
                                </Link>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={7} className="featured-img">
                              <img
                                src={PrismicHelper.optimize(
                                  featured.data.banner_image,
                                  1200
                                )}
                                alt={featured.data.banner_image.alt || ""}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Grid container spacing={2.5}>
                        {filteredResources
                          .filter((r) => r !== featured || !showFeatured)
                          .slice(
                            (curPage - 1) * PageLength,
                            (curPage - 1) * PageLength + PageLength
                          )
                          .map((resource) => (
                            <ResourceItem
                              key={resource.uid}
                              resource={resource}
                            />
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Grid container spacing={2.5} className="last-mobile">
                    <Grid item xs={12}>
                      <TextField
                        onChange={onSearchChange}
                        className="search-input"
                        variant="outlined"
                        name="search"
                        placeholder="Search Titles"
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Box className="filters">
                        <div className="filter-section">
                          <h5 className="filter-title">RESOURCE TYPE</h5>

                          {resourceTypes.map((type, i) => (
                            <div className="filter-item" key={type}>
                              <input
                                id={type.replace(" ", "-")}
                                type="checkbox"
                                name={type}
                                onClick={onTypeFilter}
                              />
                              <label htmlFor={type.replace(" ", "-")}>
                                {type.replace(/_/g, " ")}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="filter-section">
                          <h5 className="filter-title">TAGS</h5>

                          {resourceTags.map((tag, i) => (
                            <div className="filter-item" key={tag}>
                              <input
                                id={tag.replace(" ", "-")}
                                type="checkbox"
                                name={tag}
                                onClick={onTagFilter}
                                defaultChecked={
                                  filters.tags && filters.tags.includes(tag)
                                }
                              />
                              <label htmlFor={tag.replace(" ", "-")}>
                                {tag}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className="hidden-mobile">
                      <MailingListForm />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className="last-mobile">
                  <Box className="pagination-wrapper">
                    <Pagination
                      count={numPages}
                      onChange={onPageChange}
                      shape="rounded"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="last-mobile hidden-desktop">
                  <MailingListForm />
                </Grid>
              </Grid>
            )}
          </Container>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

const ResourceItem = ({ resource }) => {
  const displayImg =
    Object.getOwnPropertyNames(resource.data.banner_image).length !== 0 ? (
      <img
        src={PrismicHelper.optimize(resource.data.banner_image, 600)}
        alt={resource.data.banner_image.alt || ""}
      />
    ) : null;

  const displayTitle = resource.data.post_title[0]?.text
    ? resource.data.post_title[0].text
    : "";

  return (
    <Grid item xs={12} sm={6} className="resource-item">
      <Link to={`/resources/${resource.type}/${resource.uid}`}>
        <div className="img-container">{displayImg}</div>

        <Box className="resource-meta">
          <Box className="resource-type">
            {resource.type.replace(/_/g, " ")}
          </Box>
          <Box className="resource-title">{displayTitle}</Box>
        </Box>
      </Link>
    </Grid>
  );
};
