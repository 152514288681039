import React, { useState } from "react";
import "./Resource.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { RichText } from "prismic-reactjs";
import { ResourceMeta } from "./ResourceMeta";
import PrismicHelper from "../../helpers/prismicHelper";
import { ScrollToTop } from "../ScrollToTop";

export const Blog = ({ resource, relatedResources, headingRef }) => {
  const [content, setContent] = useState([]);

  React.useEffect(() => {
    setContent(resource.data.body_content);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [resource]);

  const displayImg =
    Object.getOwnPropertyNames(resource.data.banner_image).length !== 0 ? (
      <img
        src={PrismicHelper.optimize(resource.data.banner_image, 1920)}
        alt={resource.data.banner_image.alt || ""}
      />
    ) : null;

  const displayTitle = resource.data.post_title[0]?.text
    ? resource.data.post_title[0].text
    : "";

  return (
    <ScrollToTop>
      <Box className="resource">
        <div
          className="banner-image"
          style={{ height: !displayImg ? "340px" : "auto" }}
        >
          {displayImg}
        </div>
        <Container className="content" maxWidth={false}>
          <div className="title">
            <h1 className="focusable-heading" ref={headingRef} tabIndex={-1}>
              {displayTitle}
            </h1>

            <div className="author">
              {resource.data.author && <div>By {resource.data.author}</div>}
              {resource.data.editor && (
                <div>Edited by {resource.data.editor}</div>
              )}
            </div>
          </div>

          <div className="post-content">
            <RichText
              render={content}
              htmlSerializer={PrismicHelper.htmlSerializer}
            />
          </div>

          <ResourceMeta
            resource={resource}
            relatedResources={relatedResources}
          ></ResourceMeta>
        </Container>
      </Box>
    </ScrollToTop>
  );
};
