import React, { useRef } from "react";
import "./PrivacyStatement.scss";
import { LegalContainer } from "./LegalContainer";

export const PrivacyStatement = () => {
  const headingRef = useRef(null);
  return (
    <LegalContainer
      heading="Privacy Statement"
      headingRef={headingRef}
      title="Privacy Statement: Meddo - Strategy Management Software"
    >
      <div className="privacy-statement">
        <div className="privacy-statement__container--section">
          <p>
            <b>Meddo Technologies Incorporated</b> (<b>“Meddo”</b>, <b>“we”</b>,
            <b>“us”</b> or <b>“our”</b>) takes steps intended to meet privacy
            principles and requirements with respect to personal information
            under applicable Canadian privacy legislation. The purpose of this
            statement is to inform our customers and other individuals we deal
            with (<b>“you”</b> or <b>“your”</b>) how we collect, use, disclose
            and protect your personal information. Personal information is
            information about an identifiable individual, as more particularly
            described under applicable privacy legislation. This statement
            applies to our collection, use and disclosure of personal
            information in Canada. This statement does not apply to information
            about our employees1 or to information that is not personal
            information.
          </p>
        </div>
        <div className="privacy-statement__container--section">
          <h2>Personal Information We Collect</h2>
          <p className="privacy-statement__text--padding-bottom">
            <b>Meddo</b> and its agents and representatives collect personal
            information in a number of circumstances in the course of marketing
            and selling activities, account provision and maintenance
            activities, providing customer support and soliciting product
            feedback. Personal information we collect includes:
          </p>
          <ul>
            <li>
              name, address,telephone number, and other contact information;
            </li>
            <li>
              account information, including the name of the organization you
              are part of, credit backlog, billing information and transaction
              history;
            </li>
            <li>
              utilization information, login history, user activation and
              deactivation information;
            </li>
            <li>
              and such other information we may collect with your consent or as
              permitted or required by law.
            </li>
          </ul>
        </div>
        <div className="privacy-statement__container--section">
          <h2>Use of Personal Information</h2>
          <p className="privacy-statement__text--padding-bottom">
            <b>Meddo</b> generally uses personal information for the following
            purposes:
          </p>

          <ul>
            <li>providing support, billing and account management services;</li>
            <li>
              improving the product and services, and improving algorithms used
              in the product or services;
            </li>
            <li>
              managing, administering, collecting or otherwise enforcing
              accounts;
            </li>
            <li>
              maintaining business records for reasonable periods, and generally
              managing and administering our business including defending and
              bringing legal actions;
            </li>
            <li>
              meeting legal, regulatory, insurance, security and processing
              requirements;
            </li>
            <li>
              and otherwise with consent or as permitted or required by law.
            </li>
          </ul>
        </div>
        <div className="privacy-statement__container--section">
          <h2>Disclosure of Your Personal Information</h2>
          <p className="privacy-statement__text--padding-bottom">
            <b>Meddo</b> discloses personal information in the following
            circumstances:
          </p>
          <ul>
            <li>to provide the products or services;</li>
            <li>
              to provide authorized Client employees, directors or agents with
              access to relevant information to enable them to provide the
              products or services;
            </li>
            <li>to develop improvements to the products or services; and</li>
            <li>to otherwise comply at all times with applicable laws.</li>
          </ul>
          <p className="privacy-statement__text--padding">
            In addition to the above, personal information may be disclosed to
            our affiliates (including outside of Canada) for internal audit,
            management, billing or administrative purposes including defending
            and bringing legal actions.
          </p>
          <p className="privacy-statement__text--padding-bottom">
            <u>Service Providers.</u> <b>Meddo</b> may transfer personal
            information to outside agents or service providers (including
            affiliates of Meddo acting in this capacity) that perform services
            on our behalf, for example marketing services, business development
            services, billing services, information technology and/or data
            hosting or processing services or similar services, or otherwise to
            collect, use, disclose, store or process personal information on our
            behalf for the purposes described in this Privacy Statement. Some of
            these service providers or affiliates may be located outside of
            Canada, including in the United States, and your personal
            information may be collected, used, disclosed, stored and processed
            in the United States or elsewhere outside of Canada for the purposes
            described in this Privacy Statement. Reasonable contractual other
            measures we may take to protect your personal information while
            processed or handled by these service providers are subject to legal
            requirements in Canada, the United States and other foreign
            countries applicable to our affiliates, agents and service
            providers, for example lawful requirements to disclose personal
            information to government authorities in those countries.
          </p>
          <p className="privacy-statement__text--padding-bottom">
            <u>Business Transactions.</u> Personal information may be used by
            Meddo and disclosed to parties connected with the proposed or actual
            financing, securitization, insuring, sale, assignment or other
            disposal of all or part of Meddo or our business or assets, for the
            purposes of evaluating and/or performing the proposed transaction.
            These purposes may include, as examples:
          </p>
          <ul>
            <li>
              permitting those parties to determine whether to proceed or
              continue with the transaction;
            </li>
            <li>
              fulfilling reporting, inspection or audit requirements or
              obligations to those parties;
            </li>
          </ul>
          <p className="privacy-statement__text--padding">
            Assignees or successors of Meddo or our business or assets may use
            and disclose your personal information for similar purposes as those
            described in this Privacy Statement.
          </p>
          <p>
            Legal, Regulatory, etc. Meddo may disclose your personal information
            as necessary to meet legal, regulatory, insurance, audit, and
            security requirements, and as otherwise with your consent or as
            permitted or required by law (including as required by applicable
            Canadian and foreign laws applicable Meddo or our agents and service
            providers, and including lawful requirements to disclose personal
            information to government authorities in those countries).
          </p>
        </div>
        <div className="privacy-statement__container--section">
          <h2>Your Consent</h2>
          <p className="privacy-statement__text--padding-bottom">
            Consent to the collection, use and disclosure of personal
            information may be given in various ways. Consent can be express
            (for example, orally, electronically or on a form you may sign
            describing the intended uses and disclosures of personal
            information) or implied (for example, when you provide information
            necessary for a service you have requested). You may provide your
            consent in some circumstances where notice has been provided to you
            about our intentions with respect to your personal information and
            you have not withdrawn your consent for an identified purpose, such
            as by using an “opt out” option provided, if any. Consent may be
            given by your authorized representative (such as a legal guardian or
            a person having a power of attorney).{" "}
            <b>
              Generally, by providing us with personal information, we will
              assume that you consent to our collection, use and disclosure of
              such information for the purposes identified or described in this
              privacy statement, if applicable, or otherwise at the time of
              collection.
            </b>
          </p>
          <p className="privacy-statement__text--padding-bottom">
            You may withdraw your consent to our collection, use and disclosure
            of personal information at any time, subject to contractual and
            legal restrictions and reasonable notice. Note that if you withdraw
            your consent to certain uses of your personal information, we may no
            longer be able to provide certain of our products or services. Note
            also that where we have provided or are providing services to you,
            your consent will be valid for so long as necessary to fulfil the
            purposes described in this Privacy Statement or otherwise at the
            time of collection, and you may not be permitted to withdraw consent
            to certain necessary uses and disclosures (for example, but not
            limited to, maintaining reasonable business and transaction records,
            disclosures to Canadian and foreign government entities as required
            to comply with laws, and reporting on credit information after
            credit has been granted, if applicable).
          </p>
          <p className="privacy-statement__text--padding-bottom">
            <b>Meddo</b> collects, uses and discloses your personal information
            with your consent, except as permitted or required by law. We may be
            required or permitted under statute or regulation to collect, use or
            disclose personal information without your consent, for example to
            comply with a court order, to comply with local or federal
            regulations or a legally permitted inquiry by a government agency,
            or to collect a debt owed to us.
          </p>
          <p>
            <b>Security</b>
          </p>
          <p className="privacy-statement__text--padding-bottom">
            We take reasonable steps to protect your personal information using
            physical, electronic or procedural security measures appropriate to
            the sensitivity of the information in our custody or control, which
            may include safeguards to protect against loss or theft, as well as
            unauthorized access, disclosure, copying, use or modification.
            Authorized employees, agents and mandataries of Meddo who require
            access to your personal information in order to fulfil their job
            requirements will have access to your personal information.
          </p>
          <p>
            <b>Retention</b>
          </p>
          <p>
            Retention Personal information is retained as described in this
            Privacy Statement, and otherwise in accordance with our record
            retention policies. Our record retention periods are established
            taking into account requirements of privacy laws, the purposes for
            which the information was collected, legal and regulatory
            requirements to retain the information for minimum periods,
            limitation periods for taking legal action, and our business
            purposes.
          </p>
        </div>

        <div className="privacy-statement__container--section">
          <h2>Access, Correction and Contacting Us</h2>
          <p className="privacy-statement__text--padding-bottom">
            <b>Meddo</b> may establish and maintain a file of your personal
            information for the purposes described above, which will be
            accessible at{" "}
            <a
              className="privacy-statement__text--site"
              href="http://www.meddo.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.meddo.io
            </a>
            . If you wish to request access or correction of your personal
            information in our custody or control, you may write to the above
            address, attention{" "}
            <a
              className="privacy-statement__text--email"
              href="mailto:privacy@meddo.io"
            >
              privacy@meddo.io
            </a>
            . Your right to access or correct your personal information is
            subject to applicable legal restrictions. We may take reasonable
            steps to verify your identity before granting access or making
            corrections. If you wish to make inquiries or complaints or have
            other concerns about our personal information practices, you may
            write to us as described above, e-mail us at{" "}
            <a
              className="privacy-statement__text--email"
              href="mailto:privacy@meddo.io"
            >
              privacy@meddo.io
            </a>
            .
          </p>
          <p>
            <b>Privacy Statement Changes</b>
          </p>
          <p>
            This Privacy Statement may be revised from time to time. If we
            intend to use or disclose Personal Information for purposes
            materially different than those described in this statement, we will
            make reasonable efforts to notify affected individuals, if
            necessary, including by revising this Privacy Statement. If you are
            concerned about how your personal information is used, you should
            contact us as described above{" "}
            <b>
              or check at our website periodically at{" "}
              <a
                className="privacy-statement__text--email"
                href="http://www.meddo.io/privacy"
              >
                www.meddo.io/privacy
              </a>
            </b>{" "}
            to obtain a current copy of this statement. We urge you to request
            and review this Privacy Statement frequently to obtain the current
            version. Your continued provision of Personal Information or use of
            our services following any changes to this Privacy Statement
            constitutes your acceptance of any such changes. This privacy
            statement is effective as of <b>July 1, 2022</b>.
          </p>
          <div className="privacy-statement__container--appendix">
            <p className="privacy-statement__text--appendix">
              Employment information practices should be dealt with separately,
              if necessary.
            </p>
          </div>
        </div>
      </div>
    </LegalContainer>
  );
};
