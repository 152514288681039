import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PrismicHelper from "../../helpers/prismicHelper";

export const ResourceMeta = ({ resource, relatedResources }) => {
  function capitalize(value) {
    return value.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
  }

  const _relatedResources = relatedResources.filter(
    ({ data }) => !Object.keys(data).includes("featured_post")
  );

  return (
    <Box>
      {resource.tags.length > 0 && (
        <div className="tags">
          <h5 className="subtitle">TAGS</h5>
          {resource.tags.map((tag, i) => (
            <div className="tag" key={i}>
              <Link to={`/resources?tags=${encodeURIComponent(tag)}`}>
                <span>{capitalize(tag)}</span>
              </Link>
            </div>
          ))}
        </div>
      )}
      {_relatedResources && _relatedResources.length > 0 && (
        <div className="related-articles">
          <h5 className="subtitle">RELATED ARTICLES</h5>

          <Grid container spacing={2.5}>
            {_relatedResources.slice(0, 2).map((relatedResource) => (
              <Grid
                item
                xs={12}
                sm={5}
                className="resource-item"
                key={relatedResource.uid}
              >
                <Link
                  to={`/resources/${relatedResource.type}/${relatedResource.uid}`}
                >
                  {relatedResource.data.banner_image &&
                  Object.getOwnPropertyNames(relatedResource.data.banner_image)
                    .length !== 0 ? (
                    <img
                      className="img"
                      src={PrismicHelper.optimize(
                        relatedResource.data.banner_image,
                        600
                      )}
                      alt={relatedResource.data.banner_image.alt || ""}
                    ></img>
                  ) : (
                    <div className="img" />
                  )}

                  <Box className="resource-meta">
                    <Box className="resource-type">
                      {relatedResource.type.replace(/_/g, " ")}
                    </Box>
                    <Box className="resource-title">
                      {relatedResource.data.post_title[0].text}
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}

            {_relatedResources.length > 2 && (
              <Grid item xs={12} sm={2}>
                <Link
                  to={`/resources?tags=${resource.tags
                    .map(encodeURIComponent)
                    .join(",")}`}
                >
                  <Box className="resource-item-more">More &#8594;</Box>
                </Link>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Box>
  );
};
