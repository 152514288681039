import React from "react";
import "./Resources.scss";
import { Button, Typography, TextField } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { useSnackbar } from "notistack";
import * as emailjs from "emailjs-com";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const CssTextField = withStyles(TextField, () => ({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& label.Mui-focused": {
      color: "#1b2f64",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#1b2f64",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1b2f64",
      },
    },
  },
}));

export const MailingListForm = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [email, setEmail] = React.useState("");

  const handleClose = () => closeSnackbar();

  const onEmailChange = function (event) {
    let newEmail = event.target.value;
    setEmail(newEmail);
  };

  const onMailingListSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      enqueueSnackbar(`Email is required`, {
        variant: "error",
        className: "error-alert",
        transitionDuration: { enter: 225, exit: 100 },
        action: () => <CloseButton onClose={handleClose} />,
      });

      return;
    }

    (async () => {
      try {
        let templateParams = {
          reply_to: email,
          from_name: email,
          message_html: "I would like to sign up for your mailing list!",
        };

        const result = await emailjs.send(
          import.meta.env.REACT_APP_EMAIL_JS_SERVICE_ID,
          import.meta.REACT_APP_EMAIL_JS_TEMPLATE_ID,
          templateParams,
          import.meta.env.REACT_APP_EMAIL_JS_USER_ID
        );

        if (result.text === "OK") {
          enqueueSnackbar(`Request sent - we'll get back to you shortly`, {
            variant: "success",
            action: () => <CloseButton onClose={handleClose} />,
          });
        } else {
          enqueueSnackbar(
            `Request not sent - sorry about that, please try again`,
            {
              variant: "error",
              className: "error-alert",
              action: () => <CloseButton onClose={handleClose} />,
            }
          );
        }
      } catch (error) {
        console.error(error.text);
      }
    })();
  };

  return (
    <form className="mailing-list" onSubmit={(e) => onMailingListSubmit(e)}>
      <Typography variant="h5">
        Want to make sure you never miss out on new content?
      </Typography>
      <Typography variant="h5">
        <b>Sign up for our mailing list!</b>
      </Typography>
      <CssTextField
        className="mailing-list-input"
        variant="outlined"
        name="email"
        label="Email"
        size="small"
        fullWidth
        onChange={onEmailChange}
        inputProps={{ type: "email", required: true }}
      ></CssTextField>
      <Button className="mailing-list-submit" variant="contained" type="submit">
        Submit
      </Button>
    </form>
  );
};

const CloseButton = ({ onClose }) => (
  <IconButton
    disableFocusRipple
    disableRipple
    color="inherit"
    aria-label="close toast"
    component="label"
    onClick={onClose}
  >
    <HighlightOffIcon />
  </IconButton>
);
