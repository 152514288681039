import React from "react";
import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Pricing from "./components/Pricing/Pricing";
import { PrivacyStatement } from "./components/Legal/PrivacyStatement";
import { TermsAndConditions } from "./components/Legal/TermsAndConditions";
import { Resources } from "./components/Resources/Resources";
import { Resource } from "./components/Resources/Resource";
import { Error } from "./components/Error";
import PrismicHelper from "../src/helpers/prismicHelper";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <Error />,
  },
  {
    path: "/privacy",
    element: <PrivacyStatement />,
    errorElement: <Error />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
    errorElement: <Error />,
  },
  {
    path: "/resources",
    element: <Resources />,
    errorElement: <Error />,
    loader: () => {
      window.scrollTo({ top: 0, behavior: "auto" });
      return null;
    },
  },
  {
    path: "/resources/:type/:uid",
    element: <Resource />,
    errorElement: <Error />,
    loader: async ({ params }) => {
      try {
        const resource = await PrismicHelper.getResource(
          params.uid,
          params.type
        );
        const relatedResources = await PrismicHelper.getRelatedResources(
          resource.uid,
          resource.tags
        );

        return { resource, relatedResources };
      } catch (err) {
        return null;
      }
    },
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <SnackbarProvider
      hideIconVariant
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
