import React, { useState } from "react";
import "./Resource.scss";
import { RichText } from "prismic-reactjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { withStyles } from "tss-react/mui";
import { useSnackbar } from "notistack";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PrismicHelper from "../../helpers/prismicHelper";
import { ResourceMeta } from "./ResourceMeta";

const useStyles = makeStyles()((theme) => ({
  registerForm: {
    maxWidth: "400px",
    padding: "30px",
    borderRadius: "4px",
    background: "rgba(0,0,0,0.1)",
    margin: "0 auto",
    "& h6": {
      fontSize: "1rem",
    },
  },
  formField: {
    borderRadius: "4px",
  },
  submitButton: {
    borderRadius: 0,
    background: "#2E3D42",
    color: "#EBEBE3",
    padding: "0.5rem 2rem",
    "&:hover": {
      background: lighten("#2E3D42", 0.05),
    },
  },
  details: {
    fontSize: "1.1rem",
    position: "absolute",
    bottom: "40px",
    left: "120px",
    display: "flex",
    alignItems: "center",
    transition: "opacity 200ms",
  },
  errorAlert: {
    color: "white !important",
    backgroundColor: "#A83117 !important",
  },
  presenterImage: {
    width: "140px",
    height: "140px",
    padding: "15px",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
  },
  presenterText: {
    padding: "15px",
    "& p": {
      marginBottom: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  videoWrapper: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
}));

const CssTextField = withStyles(TextField, (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#2E3D42",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.secondary.dark,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2E3D42",
        borderRadius: "4px",
      },
      "&:hover fieldset": {
        borderColor: "#2E3D42",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2E3D42",
      },
    },
  },
}));

export const Webinar = ({ resource, relatedResources, headingRef }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [webinarDescription, setWebinarDescription] = useState([]);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [submitting, setSubmitting] = useState(false);

  React.useEffect(() => {
    setWebinarDescription(resource.data.body);
  }, [resource]);

  const onSubmitError = (err) => {
    console.error(err);
    enqueueSnackbar(
      "An error occurred. Please email caitlin.feehan@turnstonedata.com to register.",
      {
        variant: "error",
        className: classes.errorAlert,
      }
    );
  };

  const onSubmit = () => {
    if (!state.firstName || !state.lastName || !state.email) {
      enqueueSnackbar(`All fields are required.`, {
        variant: "error",
        className: classes.errorAlert,
      });
      return;
    }

    let endpoint =
      "https://api.hsforms.com/submissions/v3/integration/submit/5391409/17845144-070e-4db5-8eef-3998c4ddf09a";

    let params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fields: [
          { name: "email", value: state.email },
          { name: "firstname", value: state.firstName },
          { name: "lastname", value: state.lastName },
        ],
      }),
    };

    setSubmitting(true);

    fetch(endpoint, params)
      .then(
        (response) => {
          setSubmitting(false);
          if (response.status === 200) {
            setState({
              email: "",
              firstName: "",
              lastName: "",
            });
            enqueueSnackbar(
              `Thank you for registering! We'll be in touch shortly.`,
              {
                variant: "success",
              }
            );
          } else {
            onSubmitError(response);
          }
        },
        (err) => {
          onSubmitError(err);
          setSubmitting(false);
        }
      )
      .catch((err) => {
        onSubmitError(err);
        setSubmitting(false);
      });
  };

  return (
    <Box className="resource webinar">
      {resource && (
        <Box>
          <Header
            headingRef={headingRef}
            title="Resource: Meddo - Strategy Management Software"
          />

          <Box>
            <img
              className={
                resource.data.banner_image.dimensions.width /
                  resource.data.banner_image.dimensions.height >
                2
                  ? "banner-image wide"
                  : "banner-image thin"
              }
              src={PrismicHelper.optimize(resource.data.banner_image, 1920)}
              alt={resource.data.banner_image.alt}
            />

            <Container className="content" maxWidth={false}>
              <div
                className="title focusable-heading"
                ref={headingRef}
                tabIndex={-1}
              >
                {resource.data.post_title[0].text}
              </div>

              <div className="post-content">
                <RichText
                  render={webinarDescription}
                  htmlSerializer={PrismicHelper.htmlSerializer}
                />
              </div>

              {resource.data.recording && (
                <Box>
                  <div className="subtitle">RECORDING</div>
                  <Box className={classes.videoWrapper} marginBottom={5}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: resource.data.recording.html,
                      }}
                    />
                  </Box>
                </Box>
              )}

              <div className="subtitle">PRESENTERS</div>
              <Grid container>
                {resource.data.presenters.map((p) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ alignSelf: "center" }}
                    key={p.name}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <Box className={classes.presenterImage}>
                          <img
                            src={PrismicHelper.optimize(p.avatar, 300)}
                            alt={p.avatar.alt}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <Box className={classes.presenterText}>
                          <Typography variant="h6" style={{ fontWeight: 600 }}>
                            {p.name}
                          </Typography>
                          <Typography>{p.title}</Typography>
                          <Typography>{p.company}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {resource.data.register && (
                <Box>
                  <div className="subtitle">REGISTER</div>
                  <Box className={classes.registerForm}>
                    <Box mb={1.5}>
                      <Box>
                        <Typography variant="h6">First Name</Typography>
                      </Box>
                      <CssTextField
                        className={classes.formField}
                        variant="outlined"
                        name="firstName"
                        fullWidth
                        size="small"
                        value={state.firstName}
                        onChange={(e) =>
                          setState((oldState) => ({
                            ...oldState,
                            firstName: e.target.value,
                          }))
                        }
                      />
                    </Box>

                    <Box mb={1.5}>
                      <Box>
                        <Typography variant="h6">Last Name</Typography>
                      </Box>
                      <CssTextField
                        className={classes.formField}
                        variant="outlined"
                        name="lastName"
                        fullWidth
                        size="small"
                        value={state.lastName}
                        onChange={(e) =>
                          setState((oldState) => ({
                            ...oldState,
                            lastName: e.target.value,
                          }))
                        }
                      />
                    </Box>

                    <Box mb={1.5}>
                      <Box>
                        <Typography variant="h6">Email</Typography>
                      </Box>
                      <CssTextField
                        className={classes.formField}
                        variant="outlined"
                        name="email"
                        type="email"
                        fullWidth
                        size="small"
                        value={state.email}
                        onChange={(e) =>
                          setState((oldState) => ({
                            ...oldState,
                            email: e.target.value,
                          }))
                        }
                      />
                    </Box>
                    <Box pt={0.5}>
                      <Button
                        variant="contained"
                        className={classes.submitButton}
                        onClick={onSubmit}
                        endIcon={
                          submitting ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <></>
                          )
                        }
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              <br />
              <ResourceMeta
                resource={resource}
                relatedResources={relatedResources}
              ></ResourceMeta>
            </Container>
          </Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
};
