import React, { useRef } from "react";

import "./Pricing.scss";

import Header from "../Header/Header";
import Action from "../Action/Action";
import Footer from "../Footer/Footer";

function Pricing() {
  const headingRef = useRef(null);

  return (
    <>
      <Header
        headingRef={headingRef}
        title="Plans & Pricing: Meddo - Strategy Management Software"
      />

      <div className="pricing-landing mobile-padding">
        <div className="container text-center pricing-landing-margin">
          <h1 className="mb-5 focusable-heading" ref={headingRef} tabIndex={-1}>
            Plans &amp; Pricing
          </h1>
          <h3 className="line-height-1-5">
            Meddo works for companies of all sizes.
            <br />
            Pick the plan that fits your needs.
          </h3>
        </div>
      </div>

      <div className="pricing-details mobile-padding">
        <div className="col-sm-8 offset-sm-2 mb-4">
          <div className="row mx-lg-n2 pricing-boxes justify-content-center">
            <div className="col-lg-4 px-lg-2 px-xl-3">
              <div className="pricing-box mb-5">
                <div className="pricing-box-header">Free</div>
                <div className="pricing-box-content">
                  <div className="text-center mb-3">
                    <div className="pricing-users">
                      <b>4 Editors</b>
                      <div>Unlimited Viewers</div>
                    </div>
                  </div>
                  <div className="pricing-blurb mb-3">
                    For very small teams looking to plan and share project
                    status with stakeholders.
                  </div>
                </div>
              </div>
            </div>

            {/* NOTE: removing this until MED-1309 is resolved */}
            {/* <div className="col-lg-4 px-lg-2 px-xl-3">
              <div className="pricing-box mb-5">
                <div className="pricing-box-header">Paid</div>
                <div className="pricing-box-content">
                  <div className="text-center mb-3">
                    <div className="pricing-users">
                      <b>$10 per Editor / Month</b>
                      <div>Unlimited Viewers</div>
                    </div>
                  </div>
                  <div className="pricing-blurb mb-3">
                    For growing teams who want to ensure that stakeholders feel
                    connected to company objectives, values, and projects.
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-lg-4 px-lg-2 px-xl-3">
              <div className="pricing-box mb-5">
                <div className="pricing-box-header">Custom</div>
                <div className="pricing-box-content custom d-flex flex-column justify-content-center">
                  <div className="pricing-blurb mb-3">
                    Email us at{" "}
                    <a href="mailto:hello@meddo.io">hello@meddo.io</a> to learn
                    about our customized plans for larger organizations.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Action />

      <Footer />
    </>
  );
}

export default Pricing;
