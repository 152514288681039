import React from "react";

import "./Action.scss";

function Action() {
  return (
    <div className="meddo-call-to-action mobile-padding">
      <p>
        <strong>
          Ready to upgrade to strategy management software that reflects how
          teams actually work?
        </strong>
      </p>
      <a
        className="btn btn-primary btn-lg"
        href="https://app.meddo.io/auth/sign-up"
      >
        Get Started with Meddo
      </a>
    </div>
  );
}

export default Action;
