import React from "react";
import * as prismic from "@prismicio/client";
import { Elements } from "prismic-reactjs";

const PrismicApiUrl = import.meta.env.VITE_PRISMIC_API_URL;
const PrismicAccessToken = import.meta.env.VITE_PRISMIC_ACCESS_TOKEN;
const PrismicClient = prismic.createClient(PrismicApiUrl, {
  accessToken: PrismicAccessToken,
});

// TODO: is this returning all resources or just the first 20?
async function getResources() {
  let resources = [];

  let blogQuery = await PrismicClient.getByType("blog");

  resources = resources.concat(blogQuery.results).sort((a, b) => {
    return (
      new Date(b.first_publication_date).getTime() -
      new Date(a.first_publication_date).getTime()
    );
  });

  // let caseStudyQuery = await PrismicClient.getByType("case_study");

  // resources = resources.concat(caseStudyQuery.results);

  //** commenting out as there isn't a webinar in the design **//

  // let webinarQuery = await PrismicClient.getByType("webinar");

  // resources = resources.concat(webinarQuery.results);

  return resources;
}

async function getResource(uid, type) {
  const resource = await PrismicClient.getByUID(type, uid);

  if (resource) {
    return resource;
  }

  return null;
}

async function getRelatedResources(uid, tags) {
  const response = await PrismicClient.getBySomeTags(tags);

  return response.results.filter((r) => r.uid !== uid);
}

async function getFeaturedResource() {
  let response = await PrismicClient.getSingle("featured_post");

  return response;
}

function propsWithUniqueKey(props, key) {
  return Object.assign(props || {}, { key });
}

function htmlSerializer(type, element, content, children, key) {
  if (type === Elements.preformatted && element.text === "---") {
    return React.createElement(
      "div",
      propsWithUniqueKey({ className: "divider" }, key)
    );
  }

  if (type === Elements.image) {
    let props = { src: optimize(element, 1200), alt: element.alt || "" };
    if (props.alt.includes("[align-right]")) {
      props.src = optimize(element, 600);
      props.alt = props.alt.replace(/\[align-right\]/g, "");
      props.className = "img-float-right";
    }
    if (props.alt.includes("[align-left]")) {
      props.src = optimize(element, 600);
      props.alt = props.alt.replace(/\[align-left\]/g, "");
      props.className = "img-float-left";
    }
    if (props.alt.includes("[full-width]")) {
      props.alt = props.alt.replace(/\[full-width\]/g, "");
      props.className = "img-full-width";
    }
    return React.createElement("img", propsWithUniqueKey(props, key));
  }

  return null;
}

function optimize(img, width = 1920) {
  let queryParams = [];

  if (!img.url.includes("auto=")) {
    queryParams.push(`auto=compress,format`);
  }

  if (img.dimensions && img.dimensions.width && img.dimensions.width > width) {
    queryParams.push(`w=${width}`);
  }

  let querySeparator = img.url.includes("?") ? "&" : "?";
  let queryParamString = queryParams.join("&");
  return `${img.url}${querySeparator}${queryParamString}`;
}

const PrismicHelper = {
  getResources,
  getResource,
  getRelatedResources,
  getFeaturedResource,
  htmlSerializer,
  optimize,
};

export default PrismicHelper;
