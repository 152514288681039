import React from "react";
import { useRouteError, Link } from "react-router-dom";
import "./Error.scss";

export const Error = () => {
  const { status, statusText, message } = useRouteError();

  return (
    <div className={"error"}>
      <div className="error__container">
        <h1 className={"error__text-heading"}>Oops!</h1>
        {status === 404 ? (
          <>
            <p className={"error__text-caption"}>
              Looks like this page doesn't exist.
            </p>
            <Link className="error__text-message" to="/">
              Return to Home
            </Link>
          </>
        ) : (
          <>
            <p className={"error__text-caption"}>
              Sorry, an unexpected error has occurred.
            </p>
            <p className="error__text-message">
              <i>{statusText || message}</i>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
