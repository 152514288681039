import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useInitialFocus } from "../../hooks/useInitialFocus";

import "./Header.scss";
import logo from "../../images/meddo.svg";

function Header({ headingRef, title }) {
  const location = useLocation();
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    pageYOffset: 0,
  });

  useInitialFocus(headingRef, title);

  const navigatePage = (page, event) => {
    event.preventDefault();
    if (location.pathname !== page) {
      navigate(page);
      window.scrollTo({ top: 0, behavior: "auto" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    toggleMobileNav(false);
  };

  const toggleMobileNav = (showNav) => {
    let element = document.getElementById("mobile-nav");

    if (showNav === undefined) {
      let curState = element.style.display === "block";
      showNav = !curState;
    }

    element.style.display = showNav ? "block" : "none";
    if (showNav) {
      document.getElementById("meddo-header").classList.add("shadow");
    } else if (state.pageYOffset <= 0) {
      document.getElementById("meddo-header").classList.remove("shadow");
    }
  };

  React.useEffect(() => {
    window.onscroll = function () {
      setState((oldState) => ({
        ...oldState,
        pageYOffset: window.pageYOffset,
      }));
    };

    return () => (window.onscroll = null);
  }, [state]);

  return (
    <header
      id="meddo-header"
      className={state.pageYOffset > 0 ? "meddo-header shadow" : "meddo-header"}
    >
      <div className="container-fluid">
        <div className="row desktop-only">
          <a
            href="/"
            className="col-lg-1 offset-lg-1 offset-xl-2 align-self-center"
            onClick={(e) => navigatePage("/", e)}
          >
            <img
              alt="Meddo"
              className="meddo-header-logo clickable"
              src={logo}
              width="112"
              height="26"
            />
          </a>

          <div className="col-lg-9 col-xl-7 align-self-center text-right">
            <div className="flex-row">
              <a
                href="/"
                className={`link ${
                  location.pathname !== "/" ? "clickable" : "selected"
                }`}
                onClick={(e) => navigatePage("/", e)}
              >
                Home
              </a>
              <a
                href="/pricing"
                className={`link ${
                  location.pathname !== "/pricing" ? "clickable" : "selected"
                }`}
                onClick={(e) => navigatePage("/pricing", e)}
              >
                Pricing
              </a>
              <a
                href="/resources"
                className={`link ${
                  location.pathname !== "/resources" ? "clickable" : "selected"
                }`}
                onClick={(e) => navigatePage("/resources", e)}
              >
                Resources
              </a>
              <a className="link" href="https://app.meddo.io">
                Log In
              </a>
              <a
                className="btn btn-primary link"
                href="https://app.meddo.io/auth/sign-up"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div className="mobile-header-content mobile-only">
          <a href="/" onClick={(e) => navigatePage("/", e)}>
            <img
              alt="Meddo"
              className="meddo-header-logo clickable"
              src={logo}
              width="112"
              height="26"
            />
          </a>
          <svg
            aria-hidden="true"
            focusable="false"
            className="menu-icon"
            role="img"
            onClick={() => toggleMobileNav()}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </div>
      </div>
      <div id="mobile-nav" className="container mobile-nav">
        <a
          href="/"
          className={`link ${
            location.pathname !== "/" ? "clickable" : "selected"
          }`}
          onClick={(e) => navigatePage("/", e)}
        >
          Home
        </a>
        <a
          href="/pricing"
          className={`link ${
            location.pathname !== "/pricing" ? "clickable" : "selected"
          }`}
          onClick={(e) => navigatePage("/pricing", e)}
        >
          Pricing
        </a>
        <a
          href="/resources"
          className={`link ${
            location.pathname !== "/resources" ? "clickable" : "selected"
          }`}
          onClick={(e) => navigatePage("/resources", e)}
        >
          Resources
        </a>
        <a className="link" href="https://app.meddo.io">
          Log In
        </a>
        <a
          className="btn btn-primary link"
          href="https://app.meddo.io/auth/sign-up"
        >
          Sign Up
        </a>
      </div>
    </header>
  );
}

export default Header;
