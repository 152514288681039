import React, { useRef } from "react";
import { useLoaderData } from "react-router-dom";
import { Blog } from "./Blog";
import { Webinar } from "./Webinar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export const Resource = () => {
  const data = useLoaderData();

  const headingRef = useRef(null);

  if (!data) return null;

  return (
    <>
      <Header
        headingRef={headingRef}
        title="Resource: Meddo - Strategy Management Software"
      />
      {data.resource.type === "blog" || data.resource.type === "case_study" ? (
        <Blog
          headingRef={headingRef}
          resource={data.resource}
          relatedResources={data.relatedResources}
        />
      ) : (
        <Webinar
          resource={data.resource}
          relatedResources={data.relatedResources}
        />
      )}
      <Footer />
    </>
  );
};
