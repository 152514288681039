import React, { useRef } from "react";

import "./Home.scss";
import "./yt-lite.scss";

import emails from "../../images/emails.svg";
import status from "../../images/status.png";
import achievements from "../../images/achievements.svg";
import priorities from "../../images/priorities.png";
import giantScreen from "../../images/giant-screen.svg";
import featureOverload from "../../images/feature-overload.svg";
import progressBars from "../../images/progress-bars.svg";
import heart from "../../images/heart.svg";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

import Header from "../Header/Header";
import Action from "../Action/Action";
import Footer from "../Footer/Footer";

function Home() {
  const headingRef = useRef(null);

  return (
    <>
      <Header
        headingRef={headingRef}
        title="About Meddo: Meddo - Strategy Management Software"
      />

      <section className="container-fluid">
        <div className="row meddo-landing mobile-padding">
          <div className="col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 align-self-center">
            <h1
              className="mb-4 focusable-heading"
              ref={headingRef}
              tabIndex={-1}
            >
              Focus your organization’s strategy management with&nbsp;Meddo
            </h1>
            <p className="line-height-1-5 mb-5">
              Meddo helps businesses stay aligned on strategic goals and drive
              employee engagement in one easy-to-use app.
            </p>
            <a
              className="btn btn-primary btn-lg"
              href="https://app.meddo.io/auth/sign-up"
            >
              Sign Up
            </a>
          </div>
          <div className="order-first order-lg-last meddo-landing-img-container col-lg-5 col-xl-4 align-self-center">
            <LiteYouTubeEmbed
              id={"of3fHrIi5AM"}
              title="Meddo: Your Tool for Organizational Alignment"
              params="rel=0"
              thumbnail="https://images.prismic.io/meddo-web/d85ea5ca-aa7d-4194-a370-82549b832a81_video-poster.jpg?auto=compress,format"
              wrapperClass="yt-lite shadow"
            />
          </div>
        </div>
      </section>

      <section className="meddo-can-do mobile-padding">
        <div className="container-fluid">
          <h2 className="text-center">What Can Meddo Do?</h2>

          <div className="row meddo-remove-barriers">
            <div className="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
              <img alt="" src={emails} width="801" height="464" />
            </div>

            <div className="col-lg-5 col-xl-4 pl-sm-4 align-self-center">
              <h3 className="mb-4">Remove Communication Barriers</h3>
              <p>
                Meddo’s consistent and intuitive structure helps remove
                communication barriers between staff and leadership, and means
                your staff can spend less time sending updates and more time
                working on important corporate priorities.
              </p>
              <p>
                When company objectives shift, everyone at your organization
                should know about it. Reduce conflict and improve collaboration
                with live commenting and notifications when updates are made to
                the priorities you care about.
              </p>
            </div>
          </div>

          <div className="row meddo-strategic-goals">
            <div className="col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 align-self-center">
              <h3 className="mb-4">Clearly Map Out Strategic&nbsp;Goals</h3>
              <p>
                Strategic priority tracking doesn’t have to be overwhelming.
                Meddo gives leaders and contributors a comprehensive view of
                company objectives and their importance.
              </p>
              <p>
                With Meddo, managers can view, track, and report performance on
                key initiatives, while showing contributors their work matters
                in the big picture.
              </p>
            </div>
            <div className="col-lg-5 align-self-center order-first order-lg-last">
              <img
                alt="List of priorities in Meddo app"
                className="screenshot shadow"
                loading="lazy"
                src={priorities}
                width="1184"
                height="940"
              />
            </div>
          </div>

          <div className="row meddo-manage-everything">
            <div className="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
              <img
                alt=""
                loading="lazy"
                src={giantScreen}
                width="799"
                height="512"
              />
            </div>
            <div className="col-lg-5 col-xl-4 pl-sm-4 align-self-center">
              <h3 className="mb-4">Manage Everything in&nbsp;One&nbsp;Place</h3>
              <p>
                Tired of searching through email, slides, and Slack to stay up
                to date on initiatives or employee progress? Meddo manages
                everything in one location. Its intuitive interface allows
                leaders to filter priorities by department or individual and to
                quickly identify initiatives that are under-resourced or at
                risk.
              </p>
              <p>
                Team members can contribute, edit, and update their own
                initiatives, while leadership has an easily filtered record of
                progress.
              </p>
              <p>
                Every Priority in Meddo maintains a log of updates, giving
                everyone a clear timeline on when key actions occurred. Can’t
                remember who made that important update 6 months ago? Let Meddo
                tell you!
              </p>
            </div>
          </div>

          <div className="row meddo-strategic-goals">
            <div className="col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 align-self-center">
              <h3 className="mb-4">
                Reflect&nbsp;How Your&nbsp;Organization Actually&nbsp;Works
              </h3>
              <p>
                No matter how your company tracks corporate goals and measures
                progress—whether KPIs, OKRs, or other similar systems—you can
                easily customize Meddo to fit your needs.
              </p>
              <p>
                With sub-priorities and category grouping, it’s simple to
                organize complex relationships across departments, meaning you
                can set up Meddo in a way that actually reflects your
                organizational structure.
              </p>
            </div>
            <div className="col-lg-5 align-self-center order-first order-lg-last">
              <img
                alt="Status screen in Meddo app"
                className="screenshot shadow"
                loading="lazy"
                src={status}
                width="1184"
                height="928"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
              <img
                alt=""
                loading="lazy"
                src={achievements}
                width="801"
                height="453"
              />
            </div>

            <div className="col-lg-5 col-xl-4 pl-sm-4 align-self-center">
              <h3 className="mb-4">Recognize Employee Achievements</h3>
              <p>
                A single location for critical updates means easier and more
                accurate performance reviews. Meddo helps leaders understand
                employee contributions year-round.
              </p>
              <p>
                Filter by team member or Priority to see a clear record of
                activity and progress, and use that history to get a full
                picture of your team’s strengths, skills, growth, and leadership
                potential.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="meddo-about-us mobile-padding pt-5">
        <h2 className="mb-4">Why We Created Meddo</h2>

        <div className="container pt-3 about-us-max-width">
          <p>
            We’ve used a lot of different project management tools in a variety
            of industries over the years, and they all have something in common
            that they lack: focus.
          </p>
          <p>
            Whether they say they track OKRs, SIs, or KPIs, they’re all designed
            to do the same thing: help organizations track the progress of and
            create alignment around their goals.
          </p>
          <p>
            Meddo removes the distractions and puts the emphasis on goal
            tracking.
          </p>
        </div>
        <h3 className="mt-5 mb-4">Other apps suffer from:</h3>
        <div className="row meddo-blobs">
          <div>
            <h4>Feature Overload</h4>
            <img alt="" loading="lazy" src={featureOverload} />
            <p>
              Clunky, confusing software leads to low adoption. Flashy extras
              and add-ons shouldn’t outnumber the features you actually need!
            </p>
          </div>
          <div>
            <h4>Arbitrary Performance and Goal Tracking</h4>
            <img alt="" loading="lazy" src={progressBars} />
            <p>
              Two words: progress bars. What does it mean to be 65% done a
              project? We have no idea, and your employees don’t either.
            </p>
          </div>
          <div>
            <h4>Uninspiring User Experience</h4>
            <img alt="" loading="lazy" src={heart} />
            <p>
              Where’s the humanity? It’s hard to want to engage with software
              that feels like it was made by (and for) robots.
            </p>
          </div>
        </div>

        <p className="pt-5 pb-4 container">
          <strong>
            We designed Meddo to avoid these pitfalls and create an intuitive,
            engaging platform that your team will love to use.
          </strong>
        </p>
      </section>

      <Action />

      <Footer />
    </>
  );
}

export default Home;
