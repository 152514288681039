import React from "react";
import "./LegalContainer.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ScrollToTop } from "../ScrollToTop";

export const LegalContainer = ({ children, heading, headingRef, title }) => {
  return (
    <ScrollToTop>
      <Header headingRef={headingRef} title={title} />
      <div className="legal-container">
        <div className="legal-container__container--outer">
          <div className="legal-container__container--inner">
            <div className="legal-container__container--text">
              <h1
                className="legal-container__text--heading focusable-heading"
                ref={headingRef}
                tabIndex={-1}
              >
                {heading}
              </h1>
            </div>
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </ScrollToTop>
  );
};
