import React, { useRef } from "react";
import "./TermsAndConditions.scss";
import { LegalContainer } from "./LegalContainer";

export const TermsAndConditions = () => {
  const headingRef = useRef(null);
  return (
    <LegalContainer
      heading="Meddo Terms and Conditions of Service"
      headingRef={headingRef}
      title="Terms and conditions: Meddo - Strategy Management Software"
    >
      <div className="terms-conditions">
        <div className="terms-conditions__container--section">
          <p>Last Updated: March 15, 2021</p>
        </div>
        <div className="terms-conditions__container--section">
          <p>
            These terms and conditions of service (<b>Agreement</b>) constitute
            a legally binding agreement governing access to and use of Meddo’s
            Services. This Agreement is entered into between Meddo Technologies
            Incorporated (<b>Meddo</b>), an Ontario corporation with a principal
            place of business located at{" "}
            <b>137 Glasgow Street, Unit 101, Kitchener, ON, N2G 4X8</b> and
            entity or person placing an order (<b>Order</b>) through completion
            of an Order Form or accessing or using the Services (<b>Client</b>).
            For clarity, an Order Form may be created through completion of an
            online form on a Meddo website or application.
          </p>
        </div>
        <div className="terms-conditions__container--section">
          <p>
            By clicking “I agree” (or a similar checkbox or button), placing an
            Order, or accessing or using the Services, you indicate your assent
            to be bound by this Agreement. If you do not agree to this
            Agreement, do not use or access the Services. If you are placing an
            Order or accessing or using the Services on behalf of a company,
            organization, or other entity, then that entity is the Client. In
            that case, you are binding that entity to this Agreement and you
            represent and warrant that you are authorized to do so.
          </p>
        </div>
        <div className="terms-conditions__container--section">
          <p>
            The “Effective Date” of this Agreement is the earlier of (a) the
            date on which Client first accesses or uses the Services and (b) the
            date on which Client’s first Order is agreed to by Meddo.
          </p>
        </div>
        <div className="terms-conditions__container--section">
          <p>
            Meddo may modify this Agreement from time to time in accordance with
            Section 7.2 (Modifications) below.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.</p>
          <p>
            <b>SERVICES</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.1</p>
          <p>
            <b>Provision of Services.</b> Meddo will, subject to the terms of
            this Agreement and any applicable Supplemental Terms, make the
            Services specified in the initial Order Form and any subsequently
            authorized Order Form available to Client. Client is authorized to
            use any API and Documentation provided by Meddo as is reasonably
            necessary to use the Services. Except for the limited rights
            expressly granted herein, no other right, title or interest in the
            intellectual property rights or technology of Meddo is granted and
            all such rights are hereby expressly reserved. Client expressly
            acknowledges and agrees that the Services provided hereunder are
            solely for the benefit of Client. Any proposed use of the Services
            by Client for the benefit of any other person will require Client
            and/or such person to enter into a separate agreement with Meddo and
            to pay additional fees to Meddo.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.2</p>
          <p>
            <b>Supplemental Terms.</b> Client’s access to and use of the
            Services may be subject to Supplemental Terms.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.3</p>
          <p>
            <b>Term.</b> The Services shall commence on the Effective Date and
            continue for the period of time specified in the Order Form (
            <b>Initial Term</b>). The Initial Term shall be automatically
            renewed for successive renewal terms (each a <b>Renewal Term</b>),
            each of a length set forth on the Order Form (the Initial Term or
            any Renewal Term, as applicable, is referred to as the <b>Term</b>).
            Either party may terminate the Agreement effective at the end of the
            Initial Term or the then current Renewal Term by providing notice to
            the other party at least ninety (90) days before the end of the
            Initial Term or the then current Renewal Term.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.4</p>
          <p>
            <b>Usage Limits.</b> Services may be subject to usage limits,
            including, Authorized User restrictions. Usage limits may be
            specified in the Order Form or applicable Supplemental Terms. If
            Client exceeds a usage limit, Meddo may invoice Client and Client
            agrees to execute an Order Form for additional quantities of the
            Services promptly upon request, and/or pay any invoice for excess
            usage.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.5</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Access by Authorized Users.</b>
            </span>
            <span className="terms-conditions__text--padding">
              (a) Client may grant any Authorized Users access to and use of the
              Services subject to any limitations, conditions and restrictions
              herein.
            </span>
            <span className="terms-conditions__text--padding">
              (b) Authorized User passwords shall be kept confidential and may
              not be shared with any other individual.
            </span>
            <span className="terms-conditions__text--padding">
              (c) Client agrees that it shall remain fully responsible and
              liable for any access to or use of the Services by any Authorized
              User and any failure by an Authorized User to comply with the
              terms of this Agreement.
            </span>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.6</p>
          <p>
            <b>Restrictions on Use.</b> Client’s use of the Services is subject
            to the following restrictions and limitations. Client shall: (a) not
            (except as otherwise expressly provided herein) provide, disclose,
            sublicense or otherwise permit any person to access, use, read,
            disseminate, transmit, download or reproduce any Documentation or
            Software; (b) not adapt, translate, change, customize, enhance,
            augment, partially delete or alter, or otherwise modify, any
            Software in any manner or to any extent whatsoever, whether in whole
            or in part; (c) not, to the maximum extent permitted by applicable
            law for the purpose of permitting interoperability with Client
            Systems, disassemble, decompile, reverse engineer, or otherwise in
            any manner deconstruct all or any part off the Software; (d) not be
            permitted to use the Services to act as a “service bureau” or in a
            time-sharing, application service provider or other similar model,
            to provide the benefit of the use of the Services to any person
            except as expressly permitted hereby; (e) not use the Services to
            store or transmit infringing, libelous, or otherwise unlawful or
            tortious material, or to store or transmit material in violation of
            third-party rights; (f) not use the Services to store or transmit
            Malicious Code; (g) not disclose the results of any Software
            benchmark test without Meddo’s prior written consent; (h) not have
            any right to access or obtain a copy of the object code or source
            code to the Software; (i) not interfere with or disrupt the
            integrity or performance of any Services or third-party data
            contained therein; (j) not attempt to gain unauthorized access to
            any Software, the Services or its related systems or networks; (k)
            not permit direct or indirect access to or use of any of the
            Services in a way that circumvents a contractual usage limit; (l)
            not frame or mirror any part of any of the Services; (m) not access
            any Services in order to build a competitive product or service; (n)
            use the Services only in accordance with applicable Documentation
            and applicable laws and government regulations; (o) notify Meddo
            promptly of any unauthorized access or use of the Services.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>1.7</p>
          <p>
            <b>Client Obligations.</b> Client shall be responsible to: (a)
            reasonably assist, cooperate and facilitate the provision of any of
            the Services, including the prompt provision of information and
            assistance that Meddo may reasonably request that is required by
            Meddo in order to perform its obligations under this Agreement; (b)
            provide Meddo with access during Client’s regular business hours to
            any Client facilities or premises and Client Systems to the extent
            reasonably required by Meddo and agreed to by Client in order to
            allow Meddo to perform its obligations under this Agreement. If
            Client fails to perform any obligation or provide any assistance,
            access, information or data specified in this Agreement, Meddo shall
            be excused from its performance hereunder to the extent Meddo is
            unable to perform as a result of such Client failure.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.</p>
          <p>
            <b>ACCESS AND SERVICE DELIVERY</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.1</p>
          <p>
            <b>Support Services.</b> During the Term, Meddo shall provide Client
            with the level of technical and account support specified in the
            Order Form which shall be incorporated into this Agreement as
            Supplemental Terms.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.2</p>
          <p>
            <b>Third Party Products.</b> Client acknowledges that, depending on
            the Services configuration and deployment option selected by Client,
            the use of the Services may require the use of certain third party
            products or services that are set out and described in the Order
            Form or any Supplemental Terms (Third Party Products). Client agrees
            that Meddo has no responsibility or obligation to supply, or any
            liability whatsoever concerning, the Third Party Products and that
            Client shall be solely responsible for sourcing, acquiring and
            licensing such Third Party Products directly from the applicable
            Third Party Products vendors (unless the parties hereto otherwise
            agree in writing).
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.3</p>
          <p>
            <b>Documentation Delivery.</b> Any available and provided
            Documentation will be delivered to Client in electronic format.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.4</p>
          <p>
            <b>Hosted Environment.</b> The Client Data is hosted on servers
            located at the Hosting Provider’s facilities. Meddo may appoint a
            new Hosting Provider, relocate the Client Data to any location, or
            otherwise modify the hosting environment, at its discretion at any
            time. Client agrees to comply with any policies and terms of the
            Hosting Provider that are applicable to Client and of which Meddo
            provides Client with notice, as may be amended from time to time, or
            such other policy as Meddo may designate from time to time. Client
            acknowledges that the Hosting Provider may, without prior notice,
            monitor Client’s conduct and communications when using the Services
            in order to verify compliance with applicable laws and the Hosting
            Provider’s policies. Client acknowledges that Hosting Provider may
            cooperate with legal authorities in investigating claims of illegal
            activity involving the Services, Meddo or Client.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.5</p>
          <p>
            <b>Client Data Obligations.</b> Client is solely responsible for
            implementing safeguards to protect the security of the Client
            Systems when accessing and using the Services, including to take
            precautions against Malicious Code.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.6</p>
          <p>
            <b>Protection of Client Data.</b> Meddo will maintain
            administrative, physical, and technical safeguards for protection of
            the security, confidentiality and integrity of Client Data. Those
            safeguards will include measures for preventing unauthorized access,
            use, modification or disclosure of Client Data by Meddo and the
            Hosting Provider.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.7</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Personal Information.</b> Meddo acknowledges that in connection
              with its provision of the Services, it may have access to personal
              information. To the extent Meddo collects personal information
              through its provision of the Services, Meddo agrees:
            </span>
            <span className="terms-conditions__text--padding">
              (a) not to use such personal information for any purpose other
              than as necessary to provide the Services;
            </span>
            <span className="terms-conditions__text--padding">
              (b) not to disclose such personal information to any person except
              authorized Client employees who require access in order to provide
              the Services, or where required by law;
            </span>
            <span className="terms-conditions__text--padding">
              (c) so long as Meddo remains in possession, custody or control of
              such personal information, to protect such information in
              accordance with Section 2.6;
            </span>
            <span className="terms-conditions__text--padding">
              (d) to immediately inform Client of any actual or suspected loss,
              theft or accidental or unauthorized access, disclosure, copying,
              use, or modification of personal information;
            </span>
            <span className="terms-conditions__text--padding">
              (e) to refer all privacy related inquiries, requests or complaints
              relating to the personal information processed by Meddo in
              connection with the Services to Client;
            </span>
            <span className="terms-conditions__text--padding">
              (f) to otherwise comply at all times with applicable privacy laws.
            </span>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.8</p>
          <p>
            <b>Anti-Virus Protection.</b> Meddo agrees to use industry standard
            anti-virus protection software and use reasonable efforts to prevent
            infection of the Services with Malicious Code. Client agrees to use
            industry standard anti-virus protection software, and reasonable
            efforts, to prevent infection (via its connection to the Services)
            of the Software or Client Data with Malicious Code. The parties
            agree to notify each other as soon as reasonably possible if they
            become aware of Malicious Code in the Software or Client Data that
            could reasonably present a threat to either party and Meddo shall
            take commercially reasonable steps to eliminate the Malicious Code.
            Client shall use reasonable efforts to assist Meddo in Meddo’s
            efforts to eliminate Malicious Code. Client shall be responsible to
            eliminate Malicious Code in Client Data.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.9</p>
          <p>
            <b>Confidential Information Use / Protection.</b> Each party agrees:
            (a) not to use any Confidential Information of the other party for
            any purpose except in the performance of its obligations under the
            Agreement or as otherwise expressly permitted hereunder; (b) to
            disclose such Confidential Information of the other party only to
            its officers, directors, employees and permitted third party
            subcontractors who have a need to know such Confidential Information
            for purposes of the Agreement and who are under a duty of
            confidentiality no less restrictive than that set forth herein
            except that Meddo will, in the performance of the Services, disclose
            Client Data to the Hosting Provider and the Hosting Provider is not
            under a duty of confidentiality as restrictive than that set forth
            herein; (c) to protect such Confidential Information (other than
            Client Data) from unauthorized use, access, theft or disclosure in
            the same manner that it protects its own similar Confidential
            Information, but in no event with less care than reasonable care and
            in the case of Client Data, Meddo shall protect Client Data as
            otherwise expressly set out herein.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.10</p>
          <p>
            <b>Permitted Disclosure.</b> The foregoing restrictions on
            disclosure shall not apply with respect to any information which:
            (a) was or becomes generally known or publicly available through no
            act or failure to act on the part of the Receiving party; (b) is
            known by the Receiving party without restrictions on disclosure at
            the time of receiving such information as evidenced by its records;
            (c) is rightfully furnished to the Receiving party without
            restrictions on disclosure by a third party without a breach of such
            third party’s obligations of confidentiality; (d) is otherwise
            necessary to establish rights or enforce obligations under this
            Agreement but only to the extent that any such disclosure is
            necessary; or (e) is required by law to be disclosed by the
            Receiving party, provided that the Receiving party: (i) gives the
            Disclosing party prompt written notice of such requirement prior to
            such disclosure, (ii) provides assistance in obtaining an order
            protecting Confidential Information from disclosure, and (iii)
            discloses information only to the extent required by law and takes
            reasonable steps to remove from the Confidential Information that is
            required to be disclosed any information that a reasonable person
            would conclude is commercially sensitive to the other party.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.11</p>
          <p>
            <b>Ownership.</b> Except as otherwise expressly set forth in the
            Agreement, all Confidential Information shall remain the exclusive
            property of the Disclosing party and its affiliates, subcontractors,
            agents, employees or independent contractors that disclosed it.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>2.12</p>
          <p>
            <b>Feedback.</b> Submission of Contributions to Meddo is voluntary.
            Client Contributions are subject to the following terms: (a) Client
            warrants that Contributions do not violate any confidentiality
            obligations that Client may have to third parties and that they do
            not contain proprietary rights of third parties; (b) Client
            contributions become the property of Meddo, and by submitting them
            Client hereby assigns to Meddo all Client rights in and to them and
            waives all moral rights that Client and its representatives have;
            (c) Meddo is free to disclose and use (or refuse to disclose or use)
            any Contributions at its sole discretion; and (d) Client is not
            entitled to any compensation or reimbursement of any kind under any
            circumstances.{" "}
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.</p>
          <p>
            <b>FEES AND PAYMENT</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.1</p>
          <p>
            <b>Service Fees.</b> In consideration for the provision of the
            Services during the Term, Client shall pay to Meddo the Fees. Unless
            otherwise described in the Order Form, all Fees will be invoiced by
            Meddo on a monthly basis.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.2</p>
          <p>
            <b>Payment Due.</b> Unless otherwise agreed to by Meddo in writing,
            all invoiced amounts are due on the date of the invoice.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.3</p>
          <p>
            <b>Taxes.</b> The Fees are exclusive of all taxes imposed by
            applicable law in connection with the Fees, including, sales tax,
            goods and services tax, use, withholding or excise tax and all other
            like or similar taxes applicable to the provision of Services, and
            Client shall pay or reimburse Meddo for all such applicable taxes
            (exclusive of taxes based on Meddo’s income).
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.4</p>
          <p>
            <b>Additional Payment Terms.</b> If Client has failed to pay any
            Fees or amounts within thirty (30) days of their due date such
            unpaid amount shall bear interest from the due date to the date of
            payment at the rate of two percent (2%) per month (being 24% per
            annum), such interest to accrue from day to day and to be compounded
            on a monthly basis. If Client has failed to pay any Fees or amounts
            within sixty (60) calendar days of their due date, such failure
            shall be deemed to be a material breach of the Agreement by Client
            and in addition to any other remedies available to Meddo, Meddo
            shall be entitled, on five (5) days’ written notice to Client, to
            suspend, without liability, the provision of any Services provided
            hereunder or in connection herewith until such time as Client has
            paid all undisputed outstanding amounts in full. Payment shall be
            made without any right of set-off or deduction. Except as expressly
            provided for herein, all payments made pursuant to this Agreement
            are non-refundable. No additional term or condition included in any
            Client purchase order or other document issued by Client that has
            not been previously agreed to in writing by Meddo shall bind Meddo.{" "}
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.5</p>
          <p>
            <b>Fee Increases.</b> Fees may be increased by Meddo, after the
            Initial Term, once annually, effective as of the anniversary of the
            Effective Date (commencing on the first anniversary of the Effective
            Date). Notice of Fee increases will be provided by Meddo to Client
            at least 60 days prior to the effective date of such Fee increases.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>3.6</p>
          <p>
            <b>Compliance.</b> Meddo shall have the right (at Meddo’s own
            expense) to conduct periodic inspections and audits of Client’s use
            of the Services for the purpose of verifying Client’s compliance
            with the terms of this Agreement. If an underpayment is identified,
            Client shall within thirty (30) days pay to Meddo the full amount of
            any underpayment.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>4.</p>
          <p>
            <b>FEES AND PAYMENT</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>4.1</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Ownership of Software</b>
            </span>
            <span className="terms-conditions__text--padding">
              (a) The Software and content and other products and services
              provided through the Services are protected by intellectual
              property and copyright laws and treaties worldwide. Client
              acknowledges and agrees that all right, title and interest
              whatsoever, in and to the Software and the Documentation and other
              intellectual property and materials made available hereunder,
              including all intellectual property and other proprietary rights
              therein is, and shall be, owned solely and exclusively by Meddo
              and/or its third party licensors.
            </span>
            <span className="terms-conditions__text--padding">
              (b) Nothing in this Agreement shall, or shall be deemed or
              construed to, assign, transfer or convey to or vest in Client any
              title, rights or interest in or to any intellectual property,
              including in or to the Software or Documentation, other than the
              rights specifically and expressly granted herein. Meddo reserves
              all rights not expressly granted to Client hereunder. aware.
            </span>
            <span className="terms-conditions__text--padding">
              (c) Client shall promptly provide Meddo with written notice of any
              use of, access to, disclosure of, reproduction, or transmission of
              any of the Software, Documentation or the Services that is in
              violation of the terms of this Agreement by any person of which it
              becomes
            </span>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>4.2</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Data.</b>
            </span>
            <span className="terms-conditions__text--padding">
              (a) Subject to the license rights granted herein, Meddo
              acknowledges and agrees that all right, title and interest
              whatsoever, in and to the Client Data including all intellectual
              property and other proprietary rights therein is, and shall be,
              owned solely and exclusively by Client.
            </span>
            <span className="terms-conditions__text--padding">
              (b) Client shall have sole responsibility for the accuracy,
              quality, integrity, legality, reliability, and appropriateness of
              all Client Data. Client is solely responsible for the selection
              and implementation of procedures and processes and controls
              regarding the access, security, encryption, use and transmission
              of Client Data to Meddo and to ensure the back-up and recovery of
              any Client Data stored by Meddo as part of the Services. Client is
              solely responsible to obtain all consents that are necessary or
              required under applicable laws to provide Client Data to Meddo for
              processing in accordance with this Agreement and Meddo’ privacy
              policy.
            </span>
            <span className="terms-conditions__text--padding">
              (c) Subject to Client’s ownership of the Client Data, Client
              acknowledges and agrees that all right, title and interest
              whatsoever, in and to the Meddo Data including all intellectual
              property and other proprietary rights therein is, and shall be,
              owned solely and exclusively by Meddo. Client hereby grants Meddo
              a perpetual, irrevocable, royalty-free, fully paid-up,
              transferrable, worldwide right and license (with right of
              sublicense) to collect, analyze, use, disclose, copy, reproduce,
              create derivative works from and commercially exploit any Client
              Data incorporated in the Meddo Data for any purpose, including to
              operate, perform, improve and enhance the Services and to develop
              new services.
            </span>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>4.3</p>
          <p>
            <b>Branding</b>. Subject to Section 7.17 below, neither party shall
            use the other party’s trademarks, service marks, logos or brand
            names in connection with this Agreement or the Services without the
            other party’s prior written consent.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.</p>
          <p>
            <b>RISK MANAGEMENT</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.1</p>
          <p>
            <b>Indemnity.</b> Client agrees to indemnify, hold harmless, and,
            upon Meddo's request, defend Meddo and the other Meddo Parties from
            and against all third party claims, actions and demands, and all
            resulting liabilities, damages and losses of any type, expenses
            (including reasonable legal fees), settlements, or judgments
            suffered or incurred by such parties and that result from or arise
            out: (a) Meddo’s authorized access to and use of any Client Data
            (including Client's failure to comply with all applicable laws,
            including any applicable data protection laws), Client Systems and
            any other Client materials in the performance of Meddo’ obligations
            or otherwise contemplated hereunder; (b) Client’s use of the
            Software and Services (but exclusive of any claim or action to the
            extent attributable to Meddo’s breach of this Agreement); and (c) a
            breach of the Agreement by Client. The foregoing indemnity shall
            survive any termination or expiration of the Agreement.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.2</p>
          <p>
            <b>No Other Warranties.</b> EXCEPT AS EXPRESSLY SET OUT IN THIS
            AGREEMENT, THERE ARE NO OTHER REPRESENTATIONS, WARRANTIES, COVENANTS
            OR CONDITIONS CONCERNING THE SOFTWARE, THE SERVICES (INCLUDING
            FUNCTIONALITY, PERFORMANCE, OPERATION OR USE BY CLIENT OR
            NON-INFRINGEMENT) AND ANY OTHER SERVICES, EXPRESS OR IMPLIED, ORAL
            OR WRITTEN, INCLUDING IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABLE QUALITY OR FITNESS FOR A PARTICULAR PURPOSE. MEDDO DOES
            NOT WARRANT THAT THE OPERATION OF THE SOFTWARE OR THE SERVICES WILL
            BE UNINTERRUPTED OR ERROR-FREE (OR THAT ALL ERRORS CAN OR WILL BE
            CORRECTED) OR WILL MEET CLIENT’S REQUIREMENTS. ALL REPRESENTATIONS
            AND WARRANTIES ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A
            COURSE OF DEALING OR USAGE OF TRADE ARE EXPRESSLY DENIED AND
            DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY LAW. ANY THIRD PARTY
            PRODUCTS AND SERVICES SUPPLIED OR UTILIZED ARE PROVIDED “AS IS”
            WITHOUT ANY REPRESENTATIONS, WARRANTIES, COVENANTS OR CONDITIONS OF
            ANY KIND OR NATURE WHATSOEVER. CLIENT CONFIRMS THAT IT HAS NOT
            RELIED ON ANY REPRESENTATION, WARRANTY, CONDITION, COVENANT OR
            PROMISE MADE BY MEDDO WHICH HAS NOT BEEN EXPRESSLY STATED IN THIS
            AGREEMENT.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.3</p>
          <p>
            <b>No Indirect Damages.</b> MEDDO SHALL NOT BE LIABLE TO CLIENT OR
            ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL,
            PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING WITH RESPECT TO LOSS OF
            OR DAMAGE TO DATA, LOST PROFITS OR SAVINGS OR BUSINESS INTERRUPTION,
            TRADING LOSSES OR TRANSACTION LOSSES OR ANY OTHER CONSEQUENTIAL
            ECONOMIC LOSS) OF ANY KIND OR NATURE WHATSOEVER SUFFERED BY CLIENT
            OR ANY THIRD PARTY HOWSOEVER CAUSED (WHETHER RESULTING FROM IMPAIRED
            OR LOST DATA, DATA BREACHES, SOFTWARE OR COMPUTER FAILURE, SUPPORT
            FAILURE, OR ANY OTHER CAUSE) AND REGARDLESS OF THE FORM OR CAUSE OF
            ACTION, EVEN IF SUCH DAMAGES ARE FORESEEABLE OR MEDDO HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MEDDO IS NOT LIABLE TO
            CLIENT FOR ANY LOSS OR DAMAGES SUFFERED BY CLIENT IN CONNECTION WITH
            ANY ACT OR OMISSION OR FAILURE BY THE HOSTING PROVIDER.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.4</p>
          <p>
            <b>Limitation of Liability.</b> MEDDO’S TOTAL AGGREGATE LIABILITY
            AND OBLIGATION TO CLIENT FOR ANY AND ALL CLAIMS ARISING OUT OF OR IN
            ANY CONNECTION WITH THE SOFTWARE, SERVICES AND THIS AGREEMENT, WITH
            RESPECT TO ANY EXPENSE, DAMAGE, LOSS, INJURY, OR LIABILITY OF ANY
            KIND, REGARDLESS OF THE FORM OF ACTION OR THEORY OF LIABILITY
            (INCLUDING FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, BY STATUTE OR
            OTHERWISE) SHALL BE LIMITED TO THE ACTUAL DIRECT DAMAGES SUFFERED
            AND SHALL NOT EXCEED THE FEES ACTUALLY PAID BY CLIENT HEREUNDER FOR
            THE SERVICES DURING THE SIX MONTHS PRECEDING THE MOST RECENT CLAIM.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>5.5</p>
          <p>
            Acknowledgement and Time Limitation. EACH PARTY AGREES THAT THE
            LIMITATIONS AND EXCLUSIONS CONTAINED IN THIS SECTION 5.0  ARE
            REASONABLE BASED UPON THE COMMERCIAL CIRCUMSTANCES, AND WOULD NOT
            HAVE ENTERED INTO THE AGREEMENT BUT FOR THE LIMITATIONS CONTAINED
            HEREIN. NEITHER PARTY WILL NOT BRING A LEGAL ACTION, REGARDLESS OF
            FORM, FOR ANY CLAIM ARISING UNDER THIS AGREEMENT MORE THAN TWO (2)
            YEARS AFTER THE CAUSE OF ACTION AROSE, AND UPON THE EXPIRATION OF
            SUCH TIME LIMIT, ANY SUCH CLAIM AND ALL RESPECTIVE RIGHTS RELATED TO
            THE CLAIM SHALL LAPSE.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>6.</p>
          <p>
            <b>TERMINATION</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>6.1</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Termination.</b> The rights granted, and Services to be
              provided, under the Agreement are expressly conditioned on
              Client’s continued compliance with the terms and conditions of the
              Agreement. A party may terminate the Agreement:
            </span>
            <span className="terms-conditions__text--padding">
              (a) if the other party has breached any material provision of the
              Agreement, including any failure to pay any Fees when due, and
              such breach continues unremedied for a period of thirty (30) days
              after written notice thereof;
            </span>
            <span className="terms-conditions__text--padding">
              (b) if the other party becomes the subject of bankruptcy,
              insolvency, reorganization, receivership or other similar
              proceedings; and
            </span>
            <span className="terms-conditions__text--padding">
              (c) for any reason at any time for convenience on no less than
              ninety (90) days prior written notice to the other party.
            </span>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>6.2</p>
          <p>
            <b>Effects of Termination.</b> Upon any termination of the Agreement
            or Services: (i) Client shall be responsible to pay Meddo for all
            outstanding Fees due and not yet paid; (ii) Meddo shall cease making
            available, and Client shall cease all use of, the Services and
            Documentation and return the Documentation, and all copies thereof,
            in its possession or under its control to Meddo or at the direction
            of Meddo destroy such Documentation (and if requested provide an
            officer’s certificate attesting to the destruction of all such
            Documentation as is satisfactory to Meddo, acting reasonably); and
            (iii) each party shall return to the other party, or at the
            direction of the other party destroy, within thirty (30) calendar
            days of the termination date all Confidential Information of the
            other party. Notwithstanding the foregoing, the parties hereto agree
            that, to the extent that electronic records containing Confidential
            Information are retained as data or records for the purposes of
            backup, recovery, contingency planning or business continuity
            planning or are otherwise not accessible in the ordinary course of
            business, such data or records, to the extent not otherwise
            permanently deleted or overwritten in the ordinary course of
            business, shall not be accessed except as required for backup,
            recovery, contingency planning or business continuity purposes and,
            if restored or otherwise become accessible, will be permanently
            deleted forthwith. Any such Confidential Information held by a party
            will remain subject to the terms hereof.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.</p>
          <p>
            <b>GENERAL</b>
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.1</p>
          <p>
            <b>Entire Agreement.</b> The Agreement constitutes the entire
            agreement between the parties hereto pertaining to all the matters
            herein and supersedes all prior agreements, proposals,
            understandings, letters of intent, negotiations and discussions
            between the parties hereto, whether oral or written.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.2</p>
          <p>
            <b>Modifications.</b> From time to time, Meddo may modify this
            Agreement. Meddo will use commercially reasonable efforts to notify
            Client of the modifications and the effective date of such
            modifications through communications via Client’s account, email, or
            other means.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.3</p>
          <p>
            <b>Notice.</b> All notices or approvals required or permitted under
            the Agreement will be in writing and delivered by email
            transmission, overnight delivery service, or certified mail, and in
            each instance will be deemed given upon receipt. All notices or
            approvals will be sent: (i) to Meddo at the address specified on the
            Order Form and (ii) to Client at the physical or electronic email
            address provided on the first page of this Agreement. For notices
            sent by email the date of receipt will be deemed the date on which
            such notice is transmitted.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.4</p>
          <p>
            <b>Language.</b> It is the express wish of the parties hereto that
            the Agreement be drawn up in English. La volonté expresse des
            parties aux présentes est que ce Agreement soit rédigé en anglais.
            The parties hereto hereby waive any right to use and rely upon any
            other language.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.5</p>
          <p>
            <b>Jurisdiction.</b> The Agreement shall be exclusively governed by,
            construed and interpreted in accordance with the laws of the
            Province of Ontario, Canada. For the purpose of all legal
            proceedings, the Agreement shall be deemed to have been performed in
            the Province of Ontario, Canada and the parties hereto expressly
            confirm that the law of the Province of Ontario is the proper law.
            The parties hereto irrevocably attorn to the exclusive jurisdiction
            of the Courts of the Province of Ontario in respect of all matters
            and disputes arising hereunder.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.6</p>
          <p>
            <b>Assignment and Delegation.</b> The Agreement shall be binding
            upon and shall enure to the benefit of and be enforceable by each of
            the parties hereto, their respective successors and permitted
            assigns. Client may not assign all or any part of the Agreement
            without the prior consent of Meddo, which consent will not be
            unreasonably withheld. Meddo may assign the Agreement without the
            consent of Client at any time. Meddo may subcontract the performance
            of its obligations hereunder to any person without the prior written
            consent of Client provided that Meddo shall remain responsible for
            all such subcontractors to the extent provided herein.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.7</p>
          <p>
            <b>Independent Contractor.</b> It is expressly understood and agreed
            that each party shall be acting as an independent contractor in
            performing its obligations hereunder and shall not be considered or
            deemed to be an agent, employee, joint venturer or partner of the
            other party. Neither party by virtue of the Agreement shall have any
            right, power or authority, express or implied, to act on behalf of
            or enter into any undertaking binding the other party. Each party
            hereby covenants to pay, at its expense, and agrees to indemnify the
            other against, all income taxes, unemployment insurance premiums,
            pension plan premiums, workers’ compensation contributions, and all
            other taxes, charges and contributions which competent government
            authorities levy or require to be paid on behalf of its personnel.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.8</p>
          <p>
            <b>Equitable Relief.</b> Nothing in this Agreement shall, or shall
            be construed to, delay, limit or preclude a party from seeking, on
            notice or ex parte, equitable relief from a court of competent
            jurisdiction at any time.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.9</p>
          <p>
            <b>Exclusivity.</b> The relationship hereunder is non-exclusive.
            Nothing in the Agreement shall prevent Meddo from providing any
            services to any other person or Client from procuring services from
            another person.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.10</p>
          <p>
            <b>Compliance with Laws.</b> Each party agrees to fully comply with
            all laws applicable to their respective obligations pursuant to the
            Agreement. Client acknowledges that Meddo is providing the Services
            for Client’s use in the support or conduct of its business and that
            Client, and not Meddo, is solely responsible to ensure that Client’s
            use of the Services is in compliance with all applicable laws.{" "}
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.11</p>
          <p>
            <b>Waiver.</b> No delay or omission by a party to exercise any right
            or power it has under the Agreement or to object to the failure of
            any covenant of the other party to be performed in a timely and
            complete manner, shall impair any such right or power or be
            construed as a waiver of any succeeding breach or any other
            covenant. All waivers must be in writing and signed by the party
            waiving its rights.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.12</p>
          <p>
            <b>Severability.</b> If any provision of the Agreement is held by a
            court of competent jurisdiction to be invalid or unenforceable in
            any respect, then the remaining provisions of the Agreement, or the
            application of such provisions to persons or circumstances other
            than those as to which it is invalid or unenforceable shall not be
            affected thereby, and each such provision of the Agreement shall be
            valid and enforceable to the extent granted by law.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.13</p>
          <p>
            <b>Force Majeure.</b> Neither party shall be liable for delays in or
            for failures to perform hereunder due to causes beyond its
            reasonable control, including acts of God, acts or omissions of the
            other party or a third party, third party product or service
            failures, Internet or telecommunications outages, acts of civil or
            military authorities, fire, strikes, power, surges or outages,
            epidemics, flood, earthquakes, riot, or war (Force Majeure Event).
            Each party shall use commercially reasonable efforts to provide the
            other party with notice of any such events.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.14</p>
          <p>
            <b>Counterparts.</b> The Agreement may be executed in one or more
            counterparts, including by facsimile transmission, each of which
            when executed shall be deemed to be an original and all of which,
            taken together, shall constitute one and the same agreement.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.15</p>
          <p>
            <b>Currency.</b> All amounts referred to in the Agreement are
            expressed in Canadian Dollars, unless expressed otherwise in the
            Order Form.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.16</p>
          <p>
            <b>Further Assurances.</b> Each of the parties hereto shall promptly
            do, make, execute or deliver, or cause to be done, made, executed or
            delivered, all such further acts, documents and things as the other
            party hereto may reasonably require from time to time during the
            term of the Agreement or thereafter for the purpose of giving full
            effect to the terms of the Agreement.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.17</p>
          <p>
            <b>Public Announcements.</b> All media releases, public
            announcements and public disclosures by either party relating to
            this Agreement (but not including any disclosure required by legal,
            accounting or regulatory requirements beyond the reasonable control
            of such party) shall be coordinated with and be approved in writing
            by the other party prior to the release thereof. Notwithstanding the
            foregoing and other terms herein, Meddo shall be permitted to
            disclose, without consent of Client, in its marketing, promotional
            or other similar materials and on its website and orally, the fact
            that Client is a customer of Meddo and the general nature of the
            relationship between the parties hereto.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.18</p>
          <p>
            <b>No Third Party Beneficiaries.</b> The Agreement is solely for the
            benefit of the parties hereto, and nothing in the Agreement will be
            deemed to create any third party beneficiary rights in any person or
            entity not a party to the Agreement
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.19</p>
          <p>
            <b>Paramountcy.</b> In the event of any inconsistencies or conflicts
            between the terms of this Agreement and the terms of any Order Form
            or Supplemental Terms, the terms of this Agreement will prevail to
            the extent of the conflict or inconsistency, unless otherwise
            specifically and expressly stated in the Order Form or the
            Supplemental Terms.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.20</p>
          <p>
            <b>Interpretation.</b> In the Agreement: (a) words denoting the
            singular include the plural and vice versa and words denoting any
            gender include all genders; (b) all usage of the words “including”
            or “include” or the phrase “e.g.” in this Agreement shall mean
            “including, without limitation”; (c) any reference to a statute
            shall mean the statute in force as at the date hereof, together with
            all regulations promulgated thereunder, as the same may be amended,
            re-enacted, consolidated and/or replaced from time to time, and any
            successor statute thereto, unless otherwise expressly provided; (d)
            the division of each Agreement into separate Articles, Sections,
            Subsections and Schedule(s) and the insertion of headings is for
            convenience of reference only and shall not affect the construction
            or interpretation of this Agreement; (e) words or abbreviations
            which have well-known or trade meanings are used herein in
            accordance with their recognized meanings; (f) if any payment is
            required to be made or other action is required to be taken pursuant
            to this Agreement on a day which is not a Business Day, then such
            payment or action, as the case may be, shall be made or taken on the
            next Business Day; and (g) the terms and conditions hereof are the
            result of negotiations between the parties hereto and the parties
            hereto agree that the Agreement shall not be construed in favour of
            or against any party by reason of the extent to which any party or
            its professional advisors participated in the preparation of the
            Agreement.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.21</p>
          <p>
            <b>Survival.</b> The provisions of Articles 4.0  and 5.0  and
            Sections 2.9, 2.10, 2.11, 2.12 and 6.2, and all terms related to
            payment (until payments have been made in full) and any other terms
            herein which expressly state that such terms will survive or are
            necessary to survive to give effect to the surviving terms, shall
            survive the termination or expiration of all or any part of the
            Agreement for any reason.
          </p>
        </div>
        <div className="terms-conditions__container--points">
          <p>7.22</p>
          <p>
            <span className="terms-conditions__text--padding">
              <b>Definitions </b>
            </span>
            <span className="terms-conditions__text--padding">
              <b>API</b> means an application programming interface.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Authorized User</b> means an individual who is authorized
              hereunder to use the Services and is limited to Client’s employees
              and independent contractors engaged by Client to supplement its
              workforce, unless and to the extent otherwise provided in
              applicable Supplemental Terms.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Business Day</b> means any day except Saturday, Sunday or any
              statutory or other holiday observed by Meddo.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Client Data</b> means all data supplied or provided by,
              imported or uploaded to the Services, or generated by, or
              otherwise made available to Meddo by Client in connection with
              Meddo’s performance of the Agreement.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Client Systems</b> means all hardware, software, systems, other
              equipment, technology, intellectual property and similar items
              provided by or accessed by or used by Meddo or otherwise made
              available to Meddo by Client in connection with Meddo’s
              performance of the Agreement.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Confidential Information</b> means any information, data and
              materials (regardless of form) disclosed, made available or
              otherwise provided by or on behalf of one party (
              <b>Disclosing party</b>) to the other party (
              <b>Receiving party</b>) hereunder that: (a) is marked as
              confidential or proprietary or in a similar fashion at the time of
              disclosure, or if disclosed orally, is stated to be confidential
              at the time of disclosure, or (b) that the Receiving party could
              reasonably conclude to be confidential to the Disclosing party.
              Meddo Confidential Information includes the Software,
              Documentation and all Services pricing information. Client
              Confidential Information includes any Client Data.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Contributions</b> means feedback, ideas, comments, and
              suggestions submitted by Client to Meddo concerning the Services.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Disclosing party</b> has the meaning given to it in the
              definition of Confidential Information.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Documentation</b> means the available user guide documentation
              (whether in material or electronic form or other form) concerning
              the use of the Services.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Effective Date</b> has the meaning given to it on the Order
              Form.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Fees</b> means the fees for the Services specified on an Order
              Form.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Force Majeure Event</b> has the meaning given to it in Section
              7.13.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Hosting Provider</b> means the third party hosting provider
              contracted by Meddo to make available to Meddo servers on which to
              host the Software and provides to Meddo certain other hosting
              services (including data storage), and includes any additional
              third parties to whom the Hosting Provider further subcontracts
              elements of its responsibilities to.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Initial Term</b> has the meaning given to it in Section 1.3.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Malicious Code</b> means code, files, scripts, agents or
              programs intended to do harm, including, for example, viruses,
              worms, time bombs and Trojan horses.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Order Form</b> means any order form between Meddo and Client
              that references the Agreement pursuant to which Client orders
              Services, which Order Forms shall be incorporated into and form
              part of the Agreement.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Meddo Data</b> means (a) Client Data that has been aggregated,
              anonymized and deidentified with respect to Client or any natural
              person; and (b) the data, documents, service tickets, and other
              information submitted or provided by or on behalf of the Client or
              any Authorized User through the use of maintenance and help desk
              services and implementation services, including any and all
              corresponding service orders, requests for services and tickets
              and any log files, event files and other trace and diagnostic
              files.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Meddo Parties</b> means Meddo, its affiliates and their service
              providers, and such persons’ respective directors, officers,
              employees, shareholders and agents.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Receiving party</b> has the meaning given to it in the
              definition of Confidential Information.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Renewal Term</b> has the meaning given to it in Section 1.3.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Services</b> means the services being provided by Meddo to
              Client under any Order Form then in effect.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Software</b> means the software used by Meddo to provide the
              Services, including any and all changes, modifications,
              improvements, enhancements, additions, new features or
              functionality.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Supplemental Terms</b> may include any other terms and
              conditions incorporated into this Agreement by Meddo by reference.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Term</b> has the meaning given to it in Section 1.3.
            </span>
            <span className="terms-conditions__text--padding">
              <b>Third Party Products</b> has the meaning given to it in Section
              2.1.
            </span>
          </p>
        </div>
      </div>
    </LegalContainer>
  );
};
